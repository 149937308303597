import { graphql } from 'gatsby';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Box, Flex } from 'rebass/styled-components';
import theme from '../../../theme';
import { TagsComponent } from '../../components/Blog';
import ContextProvider from '../../components/ContextProvider';
import Layout from '../../components/Layout';
import markdownRenderer from '../../components/MarkdownRenderer';
import Section from '../../components/Section';
import TextLibrary, {
  H2,
  Paragraph,
  Small,
} from '../../components/TextLibrary';
import { Article } from '../../objects/Blog';
import { InternalLinkWithQuery } from '../../utils/LinkWithQuery';

type Props = {
  data: { contentfulArticle: Article };
  pageContext: {
    canonicalPath: string;
  };
};

const ArticlePage = ({ data, pageContext }: Props) => {
  const articleData: Article = data.contentfulArticle;
  const {
    title,
    node_locale: localeCode,
    publishDate,
    description,
    slug,
    body: {
      childMarkdownRemark: { rawMarkdownBody: bodyMarkdown },
    },
    heroImage,
    author: { name: authorName },
    tags,
    category,
  } = articleData;
  const { canonicalPath } = pageContext;

  const isGif = heroImage.mimeType === 'image/gif';

  return (
    <ContextProvider localeCode={localeCode}>
      <Layout
        title={title}
        description={description}
        colorTheme="light"
        metaImage={getSrc(heroImage.image)}
        canonicalPath={canonicalPath}>
        <article>
          <Section.Container backgroundColor="light" id="id" mt={[5, 6]}>
            <Box color="light">
              <InternalLinkWithQuery to={'../' + category.slug}>
                <Flex
                  justifyContent="center"
                  mx="auto"
                  my={[3]}
                  sx={{
                    borderTopWidth: '1px',
                    borderTopStyle: 'solid',
                    borderTopColor: theme.colors.cloudFitOrangeA11y,
                    width: '80px',
                    backgroundColor: 'cloudFitOrangeA11y',
                    textTransform: 'uppercase',
                    px: 5,
                    py: 1,
                  }}>
                  {category.name}
                </Flex>
              </InternalLinkWithQuery>
            </Box>

            <H2 as="h1">{title}</H2>

            <Flex flexDirection="column" mb={[3]}>
              <Box>
                <Small
                  textAlign={['center']}
                  mb={[2]}
                  sx={{ fontWeight: 'bold' }}>
                  {authorName}
                </Small>
              </Box>
              <Box>
                <Small
                  textAlign={['center']}
                  mb={[0]}
                  sx={{ fontWeight: 'bold' }}>
                  {publishDate}
                </Small>
              </Box>
              {false && tags && (
                <Flex justifyContent="center" mt={[2]}>
                  <TagsComponent tags={tags} slug={slug} />
                </Flex>
              )}
            </Flex>

            <TextLibrary.H5 as="h2" textAlign={['left']}>
              <summary>{description}</summary>
            </TextLibrary.H5>
            <Box my={3}>
              {isGif && <img alt={heroImage.description} src={heroImage.url} />}
              {!isGif && (
                <GatsbyImage
                  alt={heroImage.description}
                  image={getImage(heroImage.image)!}
                />
              )}
            </Box>
            <Paragraph as="div" color="dark">
              <ReactMarkdown components={markdownRenderer}>
                {bodyMarkdown}
              </ReactMarkdown>
            </Paragraph>
          </Section.Container>
        </article>
      </Layout>
    </ContextProvider>
  );
};
export default ArticlePage;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      id
      title
      node_locale
      publishDate(formatString: "MMMM D, YYYY")
      description
      slug
      heroImage {
        title
        description
        image: gatsbyImageData(width: 1920)
        mimeType
        url
      }
      body {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      author {
        name
        photo {
          title
          description
          image: gatsbyImageData(width: 100)
        }
      }
      tags
      readingTimeMinutes
      category {
        id
        name
        slug
      }
    }
  }
`;
